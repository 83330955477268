const colors = {
  transparent: 'transparent', // 0
  black: '#090f15', // 1
  white: '#f1f6f7', // 2
  headingColor: '#f1f6f7',
  textColor: '#f1f6f7', // 3
  labelColor: '#f1f6f7', // 4
  inactiveField: '#f1f6f7', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: '#1f9ad2', // 8
  primaryHover: '#1f4f84', // 9
  secondary: '#1f9ad2', // 10
  secondaryHover: '#1f4f84', // 11
  yellow: '#0d253e', // 12
  yellowHover: '#0d253e', // 13
  borderColor: '#1b1e25', //14
  primaryBoxShadow: '0px 8px 20px -6px rgba(54, 81, 126, 0.57)',
  secondaryBoxShadow: '0px 8px 20px -6px rgba(0, 184, 254, 0.5)',
};

export default colors;
